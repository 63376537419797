/******************************************
 *  Author : Suraj Sharma
 *  Created On : Mon Dec 28 2020
 *  File : index.jsx
 *******************************************/
import React from 'react';
import Login from './Login';

const LoginContainer = (props) => {
    return <Login {...props} />
}

LoginContainer.getInitialProps = async (ctx) => {
    const {query} = ctx;
    const {
        email
    } = query;

    return {
        emailInVerificationUrl: email,
    }
}

export default LoginContainer;